import { InMemoryCache } from '@apollo/client'
import possibleTypes from './possibleTypes.json'

const DEFAULT_INITIAL_STATE = {}

const createApolloCache = (initialState) =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      PaymentUser: {
        keyFields: ['promoterId'], // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
      },
      IPaymentDetails: {
        keyFields: ['token'],
      },
      FeatureSeries: {
        keyFields: ['seriesRef'],
      },
      TicketNotificationsSubscription: {
        keyFields: ['userId', 'eventId'],
      },
      SourceSavedArtist: {
        keyFields: ['userId', 'artist', ['id']],
      },
      SourceSavedArtistListGenre: {
        keyFields: ['listId', 'slug'],
      },
      BumpDecision: {
        keyFields: ['date'],
      },
      Query: {
        fields: {
          exchange: {
            /**
             * Apply cache redirects to query fields where we could fetch all the data from the cache
             * For example, GET_EXCHANGE_HEADER_QUERY can probably query the cache as exchanges are loaded in list components
             * This will save an extra network request
             *
             * https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
             */
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Exchange',
                id: args.id,
              })
            },
          },
          feature: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Feature',
                id: args.id,
              })
            },
          },
          newsArticle: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'News',
                id: args.id,
              })
            },
          },
          podcast: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Podcast',
                id: args.id,
              })
            },
          },
          review: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Review',
                id: args.id,
              })
            },
          },
          singleFeatureSeries: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'FeatureSeries',
                seriesRef: args.seriesRef,
              })
            },
          },
          /**
           * Apollo has a hard time knowing what to do when merging arrays of cache data
           * for the following types, since they have multiple key fields rather than a single ID field.
           *
           * Specify these merge functions to tell Apollo to replace the existing array with the incoming one
           * (this is the default behaviour for arrays)
           *
           * https://github.com/apollographql/apollo-client/issues/6868#issuecomment-703282751
           */
          ticketNotificationsSubscriptions: {
            merge(_existing, incoming) {
              return incoming
            },
          },
          sourceSavedArtists: {
            merge(_existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }).restore(initialState || DEFAULT_INITIAL_STATE)

export default createApolloCache
