import Tracking from 'scripts/tracking'
import TRACKING_EVENT from 'scripts/tracking/tracking-event'
import { usePerformanceContext } from './PerformanceContext'

const usePerformanceTracking = (
  performanceTrackingType: PerformanceTrackingType
) => {
  const performanceContext = usePerformanceContext()

  let skip = true
  let properties = null

  if (performanceContext) {
    const { loading, ...rest } = performanceContext
    skip = loading || rest.directNavigationDurationMs === null // directNavigationDuration will be null when the user is client side navigating
    properties = { ...rest, subtype: performanceTrackingType }
  }

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.performanceTracking,
      properties,
      skip,
    },
  })
}

enum PerformanceTrackingType {
  LoadEventListings,
}

export { PerformanceTrackingType }
export default usePerformanceTracking
