/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  sorryThatPageDoesNotExist: {
    id: 'Errors.SorryThatPageDoesNotExist',
    defaultMessage: "Sorry, that page doesn't exist.",
  },
  badRequest: {
    id: 'Errors.BadRequest',
    defaultMessage: 'Bad request',
  },
  incorrectUsernameOrPassword: {
    id: 'Errors.IncorrectUsernameOrPassword',
    defaultMessage: 'Incorrect username or password',
  },
  incorrectUsername: {
    id: 'Error.IncorrectUsername',
    defaultMessage: 'Incorrect username',
  },
  incorrectEmailOrPassword: {
    id: 'Errors.IncorrectEmailOrPassword',
    defaultMessage: 'Incorrect email or password',
  },
  incorrectPassword: {
    id: 'Errors.IncorrectPassword',
    defaultMessage: 'Incorrect password',
  },
  emailNotVerified: {
    id: 'Errors.EmailNotVerified',
    defaultMessage: 'Email address is not verified',
  },
  tooManyAttemptsTryLater: {
    id: 'Errors.TooManyAttemptsTryLater',
    defaultMessage:
      'Your account is currently locked. Please reset your password or try again later',
  },
  notFound: {
    id: 'Errors.NotFound',
    defaultMessage: 'Not found',
  },
  serverError: {
    id: 'Errors.ServerError',
    defaultMessage:
      'Something went wrong. Please refresh the page and try again.',
  },
  requiredWithPath: {
    id: 'Errors.RequiredWithPath',
    defaultMessage: '{path} is required',
  },
  required: {
    id: 'Errors.Required',
    defaultMessage: 'Required',
  },
  usernameWrongLength: {
    id: 'Errors.UsernameWrongLength',
    defaultMessage: 'Username must be between 3 and 18 characters long',
  },
  usernameInvalidFormat: {
    id: 'Errors.UsernameInvalidFormat',
    defaultMessage:
      'Username must contain only letters, numbers, and one period or hyphen',
  },
  passwordWrongLength: {
    id: 'Errors.PasswordWrongLength',
    defaultMessage: 'Password must be between 6 and 50 characters long',
  },
  dateOfBirthMustBeInThePast: {
    id: 'Errors.DateOfBirthMustBeInThePast',
    defaultMessage: 'Date of birth must be in the past',
  },
  dateOfBirthUnrealistic: {
    id: 'Errors.DateOfBirthUnrealistic',
    defaultMessage: 'Date of birth is unrealistic',
  },
  emailsDoNotMatch: {
    id: 'Errors.EmailsDoNotMatch',
    defaultMessage: 'Emails do not match',
  },
  usernameNotAvailable: {
    id: 'Errors.UsernameNotAvailable',
    defaultMessage: 'Username not available',
  },
  userAlreadyWithEmailAddress: {
    id: 'Errors.UserAlreadyWithEmailAddress',
    defaultMessage: 'An account with this email address already exists',
  },
  areaIsInvalid: {
    id: 'Errors.AreaIsInvalid',
    defaultMessage: 'This area does not exist',
  },
  emailDomainBanned: {
    id: 'Errors.EmailDomainBanned',
    defaultMessage: 'This email domain is banned',
  },
  mustBeMoreThan: {
    id: 'Errors.MustBeMoreThan',
    defaultMessage: 'Must be more than {amount}',
  },
  mustBeANumber: {
    id: 'Errors.MustBeANumber',
    defaultMessage: 'Must be a number',
  },
  noResults: {
    id: 'Errors.NoResults',
    defaultMessage: 'No results found',
  },
  noResultsTryAgain: {
    id: 'Errors.TryAgain',
    defaultMessage:
      "We couldn't find what you're looking for. Try adjusting your search.",
  },
  somethingWentWrong: {
    id: 'Errors.SomethingWentWrong',
    description: 'Error to show users when a component fails to load',
    defaultMessage:
      'Oops! Something went wrong. Please contact us if you keep seeing this error.',
  },
  noResultsForLocation: {
    id: 'Errors.NoResultsForLocation',
    defaultMessage:
      "We couldn't find that location. Try adjusting your search.",
  },
  notFoundWithEntityType: {
    id: 'Errors.NotFoundWithEntityType',
    defaultMessage: '{entityType} not found',
  },
  noEntityTypeAtUrl: {
    id: 'Errors.NoEntityTypeAtURL',
    defaultMessage: 'Sorry, there is no {entityType} at this URL',
  },
  invalidEmail: {
    id: 'Errors.InvalidEmail',
    defaultMessage: 'Invalid email',
  },
  tooLong: {
    id: 'Errors.TooLong',
    defaultMessage: '{field} cannot be longer than {amount} characters',
  },
  notUSStateProvince: {
    id: 'Errors.NotUSStateProvince',
    defaultMessage: 'Must be a valid 2-letter US state/province code',
  },
  invalidFileType: {
    id: 'Errors.InvalidFileType',
    defaultMessage: 'Invalid file type',
  },
  fileTooLarge: {
    id: 'Errors.FileTooLarge',
    defaultMessage: 'File too large',
  },
  fileTooSmall: {
    id: 'Errors.FileTooSmall',
    defaultMessage: 'File too small',
  },
  tooManyFiles: {
    id: 'Errors.TooManyFiles',
    defaultMessage: 'Too many files',
  },
  invalidDateFormat: {
    id: 'Errors.InvalidDateFormat',
    defaultMessage: 'Invalid date format',
  },
  mustBeCharacterOrSymbol: {
    id: 'Errors.MustBeCharacterOrSymbol',
    defaultMessage: "{field} can only have characters, a space, -, ` or '",
  },
  fileSizeDimensionsMustBeLessThan: {
    id: 'Errors.FileSizeDimensionsMustBeLessThan',
    defaultMessage:
      'File size must be under {size} and dimensions must be less than {dimensions} pixels.',
  },
  imageFormatUnsupported: {
    id: 'Errors.ImageFormatUnsupported',
    defaultMessage:
      'Image must be in a supported format. Supported formats are {formats}',
  },
  invalidUrl: {
    id: 'Errors.InvalidUrl',
    defaultMessage: 'Invalid URL',
  },
  urlMustBeShorterthan: {
    id: 'Errors.UrlMustBeShorterThan',
    defaultMessage: 'URL must be shorter than {length} characters',
  },
  nameMustBeShorterthan: {
    id: 'Errors.NameMustBeShorterThan',
    defaultMessage: 'Name must be shorter than {length} characters',
  },
  costMustBeShorterThan: {
    id: 'Errors.CostMustBeShorterThan',
    defaultMessage: 'Cost must be shorter than {length} characters',
  },
  invalidCostFormat: {
    id: 'Errors.InvalidCostFormat',
    defaultMessage:
      'Cannot contain text. Please enter a valid currency format.',
  },
  hyperWalletGenericErrorMessage: {
    id: 'Errors.HyperWalletGenericErrorMessage',
    defaultMessage:
      'There was a problem processing your payment details. Please <contactlink>contact us</contactlink> to resolve this issue',
  },
  mediaLinkSourceValidation: {
    id: 'Errors.MediaLinkSourceValidation',
    defaultMessage: 'Must be a valid SoundCloud, YouTube or Mixcloud link',
  },
  emojiInTitle: {
    id: 'Errors.EmojiInTitle',
    defaultMessage: 'Event title cannot contain emojis',
  },
  emojiInDescription: {
    id: 'Errors.EmojiInDescription',
    defaultMessage: 'Event description cannot contain emojis',
  },
  urlInTitle: {
    id: 'Errors.UrlInTitle',
    defaultMessage: 'Event title cannot contain URLs',
  },
  urlInPromotionalLink: {
    id: 'Errors.UrlInPromotionalLink',
    defaultMessage: 'Promotional link website name cannot contain URLs',
  },
  urlInDescription: {
    id: 'Errors.UrlInDescription',
    defaultMessage: 'Event description cannot contain URLs',
  },
  ticketAllocationLessThanMinimum: {
    id: 'Errors.TicketAllocationLessThanMinimum',
    defaultMessage: 'Minimum allocation is {minAmount}',
  },
  ticketAllocationLessThanTotalSold: {
    id: 'Errors.TicketAllocationLessThanTotalSold',
    defaultMessage: 'Allocation cannot be less than the no. of tickets sold',
  },
  mustBeEqualOrGreaterThanZero: {
    id: 'Errors.MustBeEqualOrGreaterThanZero',
    defaultMessage: 'Must be equal to or greater than 0',
  },
  ticketAllocationMustBeinteger: {
    id: 'Errors.TicketAllocationMustBeinteger',
    defaultMessage: 'Allocation must be an integer',
  },
  cannotSubmitDuplicateGenres: {
    id: 'Errors.CannotSubmitDuplicateGenres',
    defaultMessage: 'Cannot submit duplicate genres',
  },
  maxTicketLength: {
    id: 'Errors.MaxTicketLength',
    defaultMessage: 'Ticket name must be shorter than {maxLength} characters',
  },
  maxPromoterNameLength: {
    id: 'Errors.MaxPromoterNameLength',
    defaultMessage: 'Promoter name must be shorter than {maxLength} characters',
  },
  nameInUse: {
    id: 'Errors.NameInUse',
    defaultMessage: 'Name unavailable. Please choose another.',
  },
  emojisOrUrls: {
    id: 'Errors.EmojisOrUrls',
    defaultMessage: 'Name cannot contain emojis or URLs.',
  },
  promoterTerms: {
    id: 'Errors.PromoterTerms',
    defaultMessage:
      'Promoter must agree to ticketing terms in order to add tickets',
  },
  maxSourceSavedArtistListLength: {
    id: 'Errors.MaxSelectorsSavedArtistListLength',
    defaultMessage: 'List name must be shorter than {maxLength} characters',
  },
  maximumHoursBeforeEvent: {
    id: 'Errors.MaximumHoursBeforeEvent',
    defaultMessage: 'Maximum 48 hours before ticket is on sale',
  },
  minimumMinutesBeforeEvent: {
    id: 'Errors.MinimumMinutesBeforeEvent',
    defaultMessage: 'Minimum 10 minutes before ticket is on sale',
  },
  havingTroubleSavingYourInformation: {
    id: 'Errors.HavingTroubleSavingYourInformation',
    defaultMessage: 'We are having trouble saving your information',
  },
  maxVATNumberLength: {
    id: 'Errors.MaxVATNumberLength',
    defaultMessage: 'VAT number must be 15 characters or less',
  },
  twoDecimals: {
    id: 'Errors.TwoDecimals',
    defaultMessage: 'Only two decimals allowed',
  },
  mustBeAPositiveNumber: {
    id: 'Errors.MustBeAPositiveNumber',
    defaultMessage: 'Must be a positive number',
  },
  nameCannotContainSymbols: {
    id: 'Errors.NameCannotContainSymbols',
    defaultMessage: 'Name cannot contain symbols',
  },
  nameCannotContainEmojis: {
    id: 'Errors.NameCannotContainEmojis',
    defaultMessage: 'Name cannot contain emojis',
  },
  nameCannotContainOnlySpaces: {
    id: 'Errors.NameCannotContainOnlySpaces',
    defaultMessage: 'Name cannot contain only spaces',
  },
})

export default messages
