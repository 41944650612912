import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import useDirectNavigationDuration from './useDirectNavigationDuration'

type PerformanceContextState = {
  loading: boolean
  directNavigationDurationMs: number | null
}

const PerformanceContext = createContext<PerformanceContextState>({
  loading: true,
  directNavigationDurationMs: null,
})

// directNavigationDuration is only valid for the initial page load
// so keep track of when we change routes to determine when to nullify the value
const useIsClientSideNavigation = (enabled) => {
  const { events } = useRouter()
  const [clientSideNavigation, setClientSideNavigation] = useState(false)

  useEffect(() => {
    if (enabled) {
      const routeChangeStartCb = () => {
        setClientSideNavigation(true)
      }

      events.on('routeChangeStart', routeChangeStartCb)
      // Disabling as we short circuit the hook when not enabled
      // eslint-disable-next-line consistent-return
      return () => {
        events.off('routeChangeStart', routeChangeStartCb)
      }
    }

    // return undefined so that the function has a consistent return type https://stackoverflow.com/a/64886804/4942191
    return undefined
  })

  return clientSideNavigation
}

const PerformanceContextProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const enabled = useFeatureSwitch(featureSwitches.enablePerformanceTracking)
  const isClientSideNavigation = useIsClientSideNavigation(enabled)
  const directNavigationDuration = useDirectNavigationDuration(enabled)

  let contextValue = null
  if (enabled) {
    const { loading, loadDurationMs } = directNavigationDuration
    contextValue = {
      loading,
      directNavigationDurationMs: !isClientSideNavigation
        ? loadDurationMs
        : null,
    }
  }

  return (
    <PerformanceContext.Provider value={contextValue}>
      {children}
    </PerformanceContext.Provider>
  )
}

const usePerformanceContext = () => useContext(PerformanceContext)

export { usePerformanceContext }
export default PerformanceContextProvider
